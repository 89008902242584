export const UPDATE_FORM = "UPDATE_FORM"

export const onInputChange = (name, value, dispatch, formData) => {
    // console.table({"name state": formData.name});
    // console.log({"name state": formData.name});
    const { hasError, error } = validateInput(name, value)
    let isFormValid = true
  
    for (const key in formData) {
        
      const item = formData[key]
      // Check if the current field has error
      if (key === name && hasError) {
        isFormValid = false
        break
      } else if (key !== name && item.hasError) {
        // Check if any other field has error
        isFormValid = false
        break
      }
    }
  
    dispatch({
      type: UPDATE_FORM,
      data: { name, value, hasError, error, touched: false, isFormValid },
    })
  }


export const onFocusOut = (name, value, dispatch, formData) => {
    const { hasError, error } = validateInput(name, value)
    let isFormValid = true
    for (const key in formData) {
      const item = formData[key]
      if (key === name && hasError) {
        isFormValid = false
        break
      } else if (key !== name && item.hasError) {
        isFormValid = false
        break
      }
    }
  
    dispatch({
      type: UPDATE_FORM,
      data: { name, value, hasError, error, touched: true, isFormValid },
    })
  }
  
  export const validateInput = (name, value) => {
    let hasError = false,
      error = ""
    switch (name) {

      case "firstname":
        if (value.trim() === "") {
          hasError = true
          error = "First name is required"
        // } else if (!/^[a-zA-Z ]+$/.test(value)) {
        //   hasError = true
        //   error = "Invalid Name. No numbers or special characters"
        } else if (value.length<2) {
          hasError = true
          error = 'First name is too short'    
        } else if (value.length>30) {
          hasError = true
          error = 'First name is too long'   
        } else {
          hasError = false
          error = ""
        }
        break

      case "lastname":
          if (value.trim() === "") {
            hasError = true
            error = "Last name is required"
          // } else if (!/^[a-zA-Z ]+$/.test(value)) {
          //   hasError = true
          //   error = "Invalid Name. No numbers or special characters"
          } else if (value.length<2) {
            hasError = true
            error = 'Last name is too short' 
          } else if (value.length>30) {
            hasError = true
            error = 'Last name is too long'   
          } else {
            hasError = false
            error = ""
          }
          break

      case "email":
        if (value.trim() === "") {
          hasError = true
          error = "Email is required"
        } else if (
          !/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(
            value
          )
        ) {
          hasError = true
          error = "Invalid Email"
        } else if (value.length>50) {
          hasError = true
          error = 'Email is too long'   
        } else {
          hasError = false
          error = ""
        }
        break
      // case "password":
      //   if (value.trim() === "") {
      //     hasError = true
      //     error = "Password is required"
      //   } else if (value.trim().length < 8) {
      //     hasError = true
      //     error = "Password must have at least 8 characters"
      //   } else {
      //     hasError = false
      //     error = ""
      //   }
      //   break
      case "phoneno":
        if (value.trim() === "") {
          hasError = true
          error = "Phone number is required"
        } else if (isNaN(Number(value))) {
          hasError = true
          error = "Please use numbers only"
        // } else if (!/^[0-9]{11-15}$/.test(value)) {
        } else if (value.length<10) {
          hasError = true
          error = "Phone number should be at least 10 digits"
        } else if (value.length>15) {
          hasError = true
          error = 'Phone number is too long'   
        } else {
          hasError = false
          error = ""
        }
        break

      case "usertype":
        if (value.trim() === "") {
          hasError = true
          error = "User type is required"
        } else {
          hasError = false
          error = ""
        }
        break
      // case "terms":
      //   if (!value) {
      //     hasError = true
      //     error = "You must accept terms and conditions"
      //   } else {
      //     hasError = false
      //     error = ""
      //   }
      //   break

      case "orgname":
        if (value.length>50) {
          hasError = true
          error = 'Organisation name is too long'   
        } else {
          hasError = false
          error = ""
        }
        break

      case "employees":
        if  (isNaN(Number(value))) {
          hasError = true
          error = "Please use numbers only"
        } else if (value.length>7) {          
          hasError = true
          error = 'Congratulations, you are the biggest company on the planet! You might need less employees to work with us.'   
        } else {
          hasError = false
          error = ""
        }
        break

      case "servicedetails":
        if (value.length>300) {
          hasError = true
          error = 'Character limited reached - please only provide a brief summary'   
        } else {
          hasError = false
          error = ""
        }
        break

      case "marketingsource":
        if (value.length>140) {
          hasError = true
          error = 'Character limited reached - please only provide a brief summary'   
        } else {
          hasError = false
          error = ""
        }
        break

      default:
        break
    }
    return { hasError, error }
  }
  