import { Routes, Route } from 'react-router-dom';
import Layout from './layout/Layout';
import ScrollToTop from "./utilities/Scroll2top";

import HomePage from './content/homepage/HomePage';
import SoftwarePage from './content/services/Software';
import HardwarePage from './content/services/Hardware';
import ConsultancyPage from './content/services/Consultancy';
import SupportPage from './content/services/Support';
import ContactUsPage from './content/forms/ContactUs';
import RequestPage from './content/forms/Request';
// import Register2Page from './content/testing/TestRegister';
import AboutUsPage from './content/About';
import ThankYouContactPage from './content/forms/ThankYouContact';
import ThankYouRequestPage from './content/forms/ThankYouRequest';
import TestPage from './content/testing/Test';


function App() {
  return (
    <ScrollToTop>
      <Routes>
        {/* <Route exact path='/rsvp' element={<RSVPPage />} /> */}
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route exact path='/software' element={<SoftwarePage />} />
          <Route exact path='/hardware' element={<HardwarePage />} />
          <Route exact path='/consultancy' element={<ConsultancyPage />} />
          <Route exact path='/support' element={<SupportPage />} />
          <Route exact path='/contactus' element={<ContactUsPage />} />
          <Route exact path='/request' element={<RequestPage />} />
          <Route exact path='/about' element={<AboutUsPage />} />
          <Route exact path='/thankyoucontact' element={<ThankYouContactPage />} />
          <Route exact path='/thankyourrequest' element={<ThankYouRequestPage />} />
          <Route exact path='/test' element={<TestPage />} />
          {/* <Route path='*' render={() => <Redirect to="/" />}>          </Route> */}
        </Route>
      </Routes>
    </ScrollToTop>
  );
}

export default App;
