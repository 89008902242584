import style from '../../style/global.module.css';
import homestyle from './homepage.module.css';
import CardItem from '../components/CardItem';
import { MdImportantDevices, MdOpenWith, MdBatchPrediction, MdPeople } from 'react-icons/md';

const approaches = [
  {
    id: 1,
    icon: <MdImportantDevices />,
    title: 'Dynamic',
    description: 'Our agile working practices allow us to rapidly deliver results in an ever-changing world ',
  },
  {
    id: 3,
    icon: <MdOpenWith />,
    title: 'Collaborative',
    description: 'Working closely with our partners so that our clients get the optimal solution',
  },
  {
    id: 2,
    icon: <MdBatchPrediction />,
    title: 'Innovative',
    description: "Always looking ahead and evaluating the latest technologies, so we can provide expert advice",
  },
  {
    id: 4,
    icon: <MdPeople />,
    title: 'Personal',
    description: 'By understanding you and your business we build trusted relationships',
  },
]


const OurApproach = () => {
  return (
    <section className={homestyle.ContainerGrey} >
      <section className={style.sectionA} style={{ marginTop: '0px' }} id="clients">
        <section className={style.sectionB} style={{ marginTop: '0px' }} id="approach">
          <h1>Our <span className={style.highlight}>Approach </span></h1>
          <div className={style.ContainerColumnsFour}>
            {approaches.map((e) =>
              <CardItem
                key = {e.id}
                icon={e.icon}
                title={e.title}
                description={e.description}
              >
              </CardItem>
            )}
          </div>
        </section>   </section></section>
  );
};
export default OurApproach;