import "./carousel.css";
import Slider from "react-slick";
import style from '../../style/global.module.css';
import homestyle from './homepage.module.css';

const ChangePageNamePageContent = () => {

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 25000,
    // cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
  };

  return (
    <section className={homestyle.ContainerWhite} >
      <section className={style.sectionA} style={{ marginTop: '0px', paddingBottom: '0px' }} id="clients">
        <section className={style.sectionB}>
          {/* <h1><span className={style.highlight}>Testimonies </span></h1> */}
          <Slider {...settings}>
            <div className={style.testimonycontainer}>
              <div className={style.testimony}>
                <p>"Having worked with MMCY for a number of years now, I can highly recommend MMCY for their all-round knowledge in the IT sector but more importantly, what has stood out for me, is their pragmatic approach to the project at hand and their continued support. Many consultants may have the IT skills,  but not everyone can deliver them in such a patient and hands-on manner.</p>
                <p>MMCY has revolutionised the IT systems at the Sailing Club bringing us into the 21st Century.</p>
                <p>The result is that the admin processes have been streamlined across all platforms enabling a more user-friendly experience, reducing unnecessary time spent on manual tasks and thus increased productivity.</p>
                <p>Their methodical approach allows for clarity and an easy understanding of what are sometimes complex issues for the user.</p>
                <p>Never thought I’d say this, but it’s a pleasure to have IT issues with MMCY around!"</p>
                <p><b> Berenice Hicks - LCSC Manager</b></p>
              </div>
            </div>
            <div className={style.testimonycontainer}>
              <div className={style.testimony}>
                <p>"MMCY was truly a breath of fresh air to our business which was definitely struggling desperately with making the move into the modern era. MMCY came on board and reviewed our practices to establish our requirements and by doing so has not only provided us with the tools to do the job but has also taught us better working practices when implementing these tools.</p>
                <p>They stepped into the world of hospitality which he wasn't familiar with and looked at our existing systems which required updating and also worked together with us to establish what potential systems we still needed. They were able to easily switch from researching the best reservation systems on the market, to the best till systems, to the best mailing systems, to the best security systems, to the best accountancy software and the list keeps on going.</p>
                <p> I cannot recommend MMCY highly enough. They have a holistic approach and an eye for detail which is second to none. They are able to help with the tiniest of issues that can cause the greatest of frustrations and they can also help with the most challenging of projects that really require the knowledge of a true professional and expert in their field."</p>
                <p><b> Kea Kouvatsis - Linden House Manager </b></p>
              </div>
            </div>
          </Slider>
        </section>
      </section>
    </section>
  );
};
export default ChangePageNamePageContent;
