import { useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../../style/global.module.css';
import formstyle from './forms.module.css';
import ReCAPTCHA from "react-google-recaptcha";
import { UPDATE_FORM, onInputChange, onFocusOut, validateInput } from '../../utilities/FormValidation'

const initialState = {
  first_name: { value: "", touched: false, hasError: true, error: "" },
  last_name: { value: "", touched: false, hasError: true, error: "" },
  email: { value: "", touched: false, hasError: true, error: "" },
  phone_no: { value: "", touched: false, hasError: true, error: "" },
  contact_type: { value: "", touched: false, hasError: true, error: "" },
  org_name: { value: "", touched: false, hasError: true, error: "" },
  employees: { value: "", touched: false, hasError: true, error: "" },
  consulting_services: { value: false, touched: false, hasError: true, error: "" },
  support_services: { value: false, touched: false, hasError: true, error: "" },
  hardware_services: { value: false, touched: false, hasError: true, error: "" },
  software_services: { value: false, touched: false, hasError: true, error: "" },
  servicedetails: { value: "", touched: false, hasError: true, error: "" },
  marketing_source: { value: "", touched: false, hasError: true, error: "" },
  isFormValid: false,
}

const formsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM:
      const { name, value, hasError, error, touched, isFormValid } = action.data
      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched },
        isFormValid,
      }
    default:
      return state
  }
}

const Register = () => {
  const navigate = useNavigate();
  const [formData, dispatch] = useReducer(formsReducer, initialState)
  const [showError, setShowError] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const recaptchaSuccess = () => {
    setIsVerified(true);
  }
  const recaptchaExpired = () => {
    setIsVerified(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData)


    let isFormValid = true

    for (const name in formData) {
      const item = formData[name]
      const { value } = item
      const { hasError, error } = validateInput(name, value)
      if (hasError) {
        isFormValid = false
      }
      if (name) {
        dispatch({
          type: UPDATE_FORM,
          data: {
            name,
            value,
            hasError,
            error,
            touched: true,
            isFormValid,
          },
        })
      }
    }

    if (!isFormValid) {
      setShowError(true)
    } else {
      setIsLoading(true);
      const APISERVER = process.env.REACT_APP_API_SERVER
      fetch(APISERVER + 'mmcy/enquire'
        , {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            first_name: formData.first_name.value,
            last_name: formData.last_name.value,
            email: formData.email.value,
            phone_no: formData.phone_no.value,
            contact_type: formData.contact_type.value,
            org_name: formData.org_name.value,
            no_of_employees: formData.employees.value,
            consulting_services: formData.consulting_services.value,
            support_services: formData.support_services.value,
            hardware_services: formData.hardware_services.value,
            software_services: formData.software_services.value,
            services_description: formData.servicedetails.value,
            marketing_source: formData.marketing_source.value,
          }),
        })
        .then((res) => {
          setIsLoading(false);
          if (res.ok) {
            return res.json();
          } else {
            return res.json().then((data) => {
              let errorMessage = 'An error has occured!';
              // if (data && data.error && data.error.message) {
              //   errorMessage = data.error.message;
              // }

              throw new Error(errorMessage);
            });
          }
        })
        .then(() => {
          navigate('/thankyourrequest');
        })
        .catch((err) => {
          setIsLoading(false);
          alert('Unable to send your message, please try again');
        });


    }

    // Hide the error message after 5 seconds

    setTimeout(() => {
      setShowError(false)
    }, 5000)

  }

  return (
    <section className={style.ContainerGrey} >
      <section className={style.sectionA} style={{ marginTop: '0px' }}>
        <section className={style.sectionC} >
          <br></br>
          <h1><span className={style.highlight}> Enquire </span> Now</h1>
          <h1> </h1>

          <form onSubmit={handleSubmit}>
            <div className={style.registerContainer}>
              <p>If you're interested in our services, complete this form and one of our team will get in touch.</p>
              <div className={style.control}>
                <label>First name <red>*</red></label>
                <input
                  type="text"
                  name='first_name'
                  id="first_name"
                  placeholder=''
                  value={formData.first_name.value}
                  onChange={e => {
                    onInputChange("first_name", e.target.value, dispatch, formData)
                  }}
                  onBlur={e => {
                    onFocusOut("first_name", e.target.value, dispatch, formData)
                  }}
                />
                {formData.first_name.touched && formData.first_name.hasError && (
                  <div className={formstyle.error}>{formData.first_name.error}</div>
                )}
              </div>
              <div className={style.control}>
                <label>Last name <red>*</red></label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={formData.last_name.value}
                  onChange={e => {
                    onInputChange("last_name", e.target.value, dispatch, formData)
                  }}
                  onBlur={e => {
                    onFocusOut("last_name", e.target.value, dispatch, formData)
                  }}
                />
                {formData.last_name.touched && formData.last_name.hasError && (
                  <div className={formstyle.error}>{formData.last_name.error}</div>
                )}
              </div>
              <div className={style.control}>
                <label>Email <red>*</red></label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email.value}
                  onChange={e => {
                    onInputChange("email", e.target.value, dispatch, formData)
                  }}
                  onBlur={e => {
                    onFocusOut("email", e.target.value, dispatch, formData)
                  }}
                />
                {formData.email.touched && formData.email.hasError && (
                  <div className={formstyle.error}>{formData.email.error}</div>
                )}
              </div>

              <div className={style.control}>
                <label>Phone number <red>*</red></label>
                <input
                  type="text"
                  name="phone_no"
                  id="phone_no"
                  value={formData.phone_no.value}
                  onChange={e => {
                    onInputChange("phone_no", e.target.value, dispatch, formData)
                  }}
                  onBlur={e => {
                    onFocusOut("phone_no", e.target.value, dispatch, formData)
                  }}
                />
                {formData.phone_no.touched && formData.phone_no.hasError && (
                  <div className={formstyle.error}>{formData.phone_no.error}</div>
                )}
              </div>

              <div className={style.control}>
                <label>Business or Personal? <red>*</red></label>
                <select
                  name="contact_type"
                  id="contact_type"
                  value={formData.contact_type.value}
                  onChange={e => {
                    onInputChange("contact_type", e.target.value, dispatch, formData)
                  }}
                  onBlur={e => {
                    onFocusOut("contact_type", e.target.value, dispatch, formData)
                  }}
                >  <option value=''></option>
                  <option value='Business'>Business</option>
                  <option value='Personal'>Personal</option>
                </select>
                {formData.contact_type.touched && formData.contact_type.hasError && (
                  <div className={formstyle.error}>{formData.contact_type.error}</div>
                )}
              </div>

              <div className={style.control} hidden={formData.contact_type.value !== 'Business'} >
                <label>Name of Organisation</label>
                <input
                  type="text"
                  name="org_name"
                  id="org_name"
                  value={formData.org_name.value}
                  onChange={e => {
                    onInputChange("org_name", e.target.value, dispatch, formData)
                  }}
                  onBlur={e => {
                    onFocusOut("org_name", e.target.value, dispatch, formData)
                  }}
                />
                {formData.org_name.touched && formData.org_name.hasError && (
                  <div className={formstyle.error}>{formData.org_name.error}</div>
                )}
              </div>

              <div className={style.control} hidden={formData.contact_type.value !== 'Business'}>
                <label>Number of Employees</label>
                <input
                  type="text"
                  name="employees"
                  id="employees"
                  value={formData.employees.value}
                  onChange={e => {
                    onInputChange("employees", e.target.value, dispatch, formData)
                  }}
                  onBlur={e => {
                    onFocusOut("employees", e.target.value, dispatch, formData)
                  }}
                />
                {formData.employees.touched && formData.employees.hasError && (
                  <div className={formstyle.error}>{formData.employees.error}</div>
                )}
              </div>

              <div>
                <label className={style.control}><b>Which services are you interest in?</b></label>
                <br></br>
                <br></br>
                <label className={formstyle.checkboxcontainer}>Consultancy Services
                  <input
                    type="checkbox"
                    name="consulting_services"
                    checked={formData.consulting_services.value}
                    onChange={e => {
                      onFocusOut("consulting_services", e.target.checked, dispatch, formData)
                    }}
                  />
                  <span className={formstyle.checkboxcheckmark}></span>
                </label>
                <label className={formstyle.checkboxcontainer}>Support Services
                  <input
                    type="checkbox"
                    name="support_services"
                    checked={formData.support_services.value}
                    onChange={e => {
                      onFocusOut("support_services", e.target.checked, dispatch, formData)
                    }}
                  />
                  <span className={formstyle.checkboxcheckmark}></span>
                </label>
                <label className={formstyle.checkboxcontainer}>Hardware Services
                  <input
                    type="checkbox"
                    name="hardware_services"
                    checked={formData.hardware_services.value}
                    onChange={e => {
                      onFocusOut("hardware_services", e.target.checked, dispatch, formData)
                    }}
                  />
                  <span className={formstyle.checkboxcheckmark}></span>
                </label>
                <label className={formstyle.checkboxcontainer}>Software Services
                  <input
                    type="checkbox"
                    name="software_services"
                    checked={formData.software_services.value}
                    onChange={e => {
                      onFocusOut("software_services", e.target.checked, dispatch, formData)
                    }}
                  />
                  <span className={formstyle.checkboxcheckmark}></span>
                </label>
              </div>
              <div className={style.control}>
                <br></br>
                <label>Details of the services you required</label>
                <textarea
                  type='textarea'
                  maxlength="300"
                  rows='4'
                  name='servicedetails'
                  id="servicedetails"
                  value={formData.servicedetails.value}
                  onChange={e => {
                    onInputChange("servicedetails", e.target.value, dispatch, formData)
                  }}
                  onBlur={e => {
                    onFocusOut("servicedetails", e.target.value, dispatch, formData)
                  }}
                />
                {formData.servicedetails.touched && formData.servicedetails.hasError && (
                  <div className={formstyle.error}>{formData.servicedetails.error}</div>
                )}
              </div>
              <div className={style.control}>
                <label>How did you hear about us?</label>
                <input
                  type="text"
                  maxlength="140"
                  name="marketing_source"
                  id="marketing_source"
                  value={formData.marketing_source.value}
                  onChange={e => {
                    onInputChange("marketing_source", e.target.value, dispatch, formData)
                  }}
                  onBlur={e => {
                    onFocusOut("marketing_source", e.target.value, dispatch, formData)
                  }}
                />
                {formData.marketing_source.touched && formData.marketing_source.hasError && (
                  <div className={formstyle.error}>{formData.marketing_source.error}</div>
                )}
              </div>
              <div className={style.recaptcha}>
                <ReCAPTCHA
                  // ref={recaptchaRef}
                  sitekey="6Ld6P-gaAAAAAJF12XEkrEg0Pn6-IlJPaTPCdh5S"
                  onChange={recaptchaSuccess}
                  onExpired={recaptchaExpired}
                />
              </div>
              {showError && !formData.isFormValid && (
                <div className={formstyle.formerror}>
                  Please check the form for errors.
                </div>
              )}
            </div>

            <div className={style.actions}>
              {!isLoading && (
                <button
                  disabled={!isVerified}
                >Submit Request</button>
              )}
              {isLoading && <p>Sending request...</p>}
            </div>
          </form>
        </section>
      </section>
    </section>
  );
};

export default Register;