import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../../style/global.module.css';
// import formstyle from './forms.module.css';
import ReCAPTCHA from "react-google-recaptcha";
import WhatsAppButtonWhiteSmall from '../static/WhatsAppButtonWhiteSmall.png';
import WhatsAppButtonGreenSmall from '../static/WhatsAppButtonGreenSmall.png';

const ContactUs = () => {
  const navigate = useNavigate();
  const first_nameInputRef = useRef();
  const last_nameInputRef = useRef();
  const emailInputRef = useRef();
  const phoneNoInputRef = useRef();
  const messageInputRef = useRef();
  const recaptchaRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const recaptchaSuccess = () => {
    setIsVerified(true);
  }
  const recaptchaExpired = () => {
    setIsVerified(false);
  }

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredfirst_name = first_nameInputRef.current.value;
    const enteredlast_name = last_nameInputRef.current.value;
    const enteredEmail = emailInputRef.current.value;
    const enteredphoneNo = phoneNoInputRef.current.value;
    const enteredmessage = messageInputRef.current.value;

    setIsLoading(true);

    const APISERVER = process.env.REACT_APP_API_SERVER

    fetch(APISERVER + 'mmcy/contactus', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: enteredfirst_name,
        last_name: enteredlast_name,
        email: enteredEmail,
        mobile: enteredphoneNo,
        message: enteredmessage,
      }),
    })
      .then((res) => {
        setIsLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let errorMessage = 'An error has occured!';
            // if (data && data.error && data.error.message) {
            //   errorMessage = data.error.message;
            // }

            throw new Error(errorMessage);
          });
        }
      })
      .then(() => {
        navigate('/thankyoucontact');
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('err.message', err.message)
        alert('Unable to send your message, please try again');
      });
  };

  // Whatsapp button to Green.
  const [isShown, setIsShown] = useState(false);

  return (
    <section className={style.ContainerGrey} >
      <section className={style.sectionA} id='ContactUsSection'>
        <h1><span className={style.highlight}>Contact </span> Us</h1>
        <section className={style.sectionC}>
          <a href="https://wa.me/447593109389"
            target="_blank"
            rel="noreferrer"
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}>
            {isShown ?
              <img
                className={style.whatsapp}
                src={WhatsAppButtonGreenSmall}
                alt="WhatsAppClicktoChat" /> :
              <img
                className={style.whatsapp}
                src={WhatsAppButtonWhiteSmall}
                alt="WhatsAppClicktoChat" />}
          </a>
          <p>or send us a message</p>
          <form onSubmit={submitHandler}>
            <div className={style.registerContainer}>
              <div className={style.control}>
                <label htmlFor='first_name'>First name <span className={style.redtext}>*</span></label>
                <input type='text' id='first_name' required ref={first_nameInputRef} />
              </div>
              <div className={style.control}>
                <label htmlFor='last_name'>Last name <span className={style.redtext}>*</span></label>
                <input type='text' id='last_name' required ref={last_nameInputRef} />
              </div>
              <div className={style.control}>
                <label htmlFor='email'>Email <span className={style.redtext}>*</span></label>
                <input type='email' id='email' required ref={emailInputRef} />
              </div>
              <div className={style.control}>
                <label htmlFor='phoneno'>Phone Number</label>
                <input type='text' id='phoneno' maxLength="11" ref={phoneNoInputRef} />
              </div>
              <div
                className={[style.control, style.message].join(" ")}
              >
                <label htmlFor='message'>Message <span className={style.redtext}>*</span></label>
                <textarea id='message' rows="4" required ref={messageInputRef} />
              </div>
              <div className={style.recaptcha}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Ld6P-gaAAAAAJF12XEkrEg0Pn6-IlJPaTPCdh5S"
                  onChange={recaptchaSuccess}
                  onExpired={recaptchaExpired}
                />
              </div>
            </div>
            <div className={style.actions}>
              {!isLoading && (
                <button
                  disabled={!isVerified}
                >Send</button>
              )}
              {isLoading && <p>Sending request...</p>}
            </div>
          </form>
        </section>
      </section>
    </section>
  );
};

export default ContactUs;