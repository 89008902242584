import { useState, } from 'react';
import { Link } from 'react-router-dom'
import navstyle from './layout.module.css';
import mmcynavlogo from '../content/static/mmcylogos/SecondaryNoWordsBorder-Opt.svg';
import mmcynavlogoonhover from '../content/static/mmcylogos/PrimaryTransparentNoWordsBorder-Opt.svg';

const MainNavigation = () => {

  //main nav menu
  const [menuToggle, setMenuToggle] = useState(false);
  const closeMenu = () => setMenuToggle(false);
  const handleNavMenuClick = () => {
    setMenuToggle(!menuToggle)
  }

  // MMCY Logo Hover over.
  const [isLogoShown, setIsLogoShown] = useState(false);
  const PORTAL = process.env.REACT_APP_PORTAL + 'signin'

  const nav_menu_signed_out = (
    <>
      <div className={navstyle.mmcynavlogodiv}>
        <a href='/'
          onClick={closeMenu}
          onMouseEnter={() => setIsLogoShown(true)}
          onMouseLeave={() => setIsLogoShown(false)}>
          {isLogoShown ?
            <img
              className={navstyle.mmcynavlogo}
              src={mmcynavlogoonhover}
              alt="MMCY Business Solutions Logo" />
            :
            <img
              className={navstyle.mmcynavlogo}
              src={mmcynavlogo}
              alt="MMCY Business Solutions Logo" />
          }
        </a>
      </div>
      <div className={navstyle.mmcynavlinksdiv}>
        {/* <Link to="/" onClick={closeMenu} className={navstyle.hidden}>mmcy.uk</Link> */}
        <Link to='/consultancy' onClick={closeMenu}>Consultancy</Link>
        <Link to='/support' onClick={closeMenu}>Support </Link>
        <Link to='/software' onClick={closeMenu}>Software</Link>
        <Link to='/hardware' onClick={closeMenu}>Hardware</Link>
        {/* <a href='/#SoftwareSection' onClick={closeMenu}>Support</a> */}
        <Link to='/about' onClick={closeMenu}>About </Link>
        <Link to='/contactus' onClick={closeMenu}>Contact </Link>
        {/* <a href={PORTAL} onClick={closeMenu}> Sign In</a> */}
        {/* <Link to='/test' onClick={closeMenu}>Test </Link> */}
      </div>
    </>
  );


  return (
    <div className={navstyle.topnav} id="myTopnav">
      <div className={menuToggle ? navstyle.topnavresponsive : navstyle.topnav}>
        <a className={navstyle.hamMenu}
          onClick={handleNavMenuClick}>
          <div className={navstyle.bar1}></div>
          <div className={navstyle.bar2}></div>
          <div className={navstyle.bar3}></div>
        </a>
        <div className={navstyle.navLinks} >
          {nav_menu_signed_out}
        </div>
        <div className={navstyle.userMenu}>
          {/* <Link to="/request" className={navstyle.userMenu} onClick={closeMenu}><button className={navstyle.actions}>Contact Us</button></Link> */}
        </div>
      </div>
    </div>


  );
};

export default MainNavigation;
