import style from '../../style/global.module.css';
import CardItem from '../components/CardItem';
import { MdOutlineLanguage, MdOutlineAddTask, MdFilterDrama, MdOutlineApi, MdOutlineFilterVintage, MdDashboard } from 'react-icons/md';
import swlife from '../static/Softwarelifecycle.png';

const Software = () => {

  const software = [
    {
      id: 1,
      icon: <MdOutlineLanguage />,
      title: 'Website hosting and domain management',
      description: 'Let us help you manage your web presence.',
      link: "/",
    },
    {
      id: 2,
      icon: <MdOutlineAddTask />,
      title: 'System selection and implementation',
      description: 'Our analysts can understand your problems and find the approriate solution for your business.',
      link: "/",
    },
    {
      id: 3,
      icon: <MdOutlineFilterVintage />,
      title: 'Bespoke development',
      description: 'Our in house developers can build the perfect software for your needs.',
      link: "/",
    },
    {
      id: 4,
      icon: <MdFilterDrama />,
      title: 'Microsoft 365 / Google Workspace',
      description: 'We can move your business into the cloud and then take care of the admin for you.',
      link: "/",
    },
    {
      id: 5,
      icon: <MdOutlineApi />,
      title: 'User training',
      description: 'Need your users to learn new software, let us help.',
      link: "/",
    },
    {
      id: 6,
      icon: <MdDashboard />,
      // icon: <img src={cogicon} width="1" height="1" className={style.CogIcon} alt="gear" />,
      title: 'System Optimisation',
      description: 'Are you getting the most out of your exisiting software.',
      link: "/",
    },
  ]

  return (
    <>        
    <section className={style.ContainerGrey} >
      <section className={style.sectionA}>
        <section className={style.sectionB} >
          <h1>Software <span className={style.highlight}>  Services</span> </h1>
          <p>To keep up in an increasingly competitive and fast changing business landscape, the tools you use need to be up-to-date and available anywhere and anytime. At MMCY Business Solutions we can help you make the most out of your software so that you can make your services or products available to customers anytime and anywhere.</p>
          <div className={style.ContainerColumnsThree}>
            {software.map((e) =>
              <CardItem
                icon={e.icon}
                title={e.title}
                description={e.description}>
              </CardItem>
            )}
          </div>
          <div>
          </div>
        </section>
      </section>
    </section>
      <section className={style.sectionA} style={{ marginTop: '0px' }}>
        <section className={style.sectionB}>
          <h1> <span className={style.highlight}> Software </span> Lifecycle</h1>
          <p>Each stage of the software lifecycle requires different skills to ensure successful delivery. Let us guide your through each stage.</p>
          <img src={swlife} alt="Software Lifecycle" className={style.lifecyclImage} />

        </section>
      </section>

    </>
  );
};

export default Software;