import style from '../../style/global.module.css';
import homestyle from './homepage.module.css';
import CardServices from './CardServices';
import consultancy from '../static/homepageimages/consultancy.jpg';
import hardware from '../static/homepageimages/hardware.jpg';
import software from '../static/homepageimages/software.jpg';
import support from '../static/homepageimages/support.jpg';

const OurServices = () => {
  return (
    <section className={homestyle.ContainerWhite}>
      <section className={style.sectionA} style={{ marginTop: '0px' }} id="servcies">
        <section className={style.sectionB} style={{ marginTop: '0px' }}>
          <h1>Our <span className={style.highlight}>Services </span></h1>

          <CardServices
            title='Business Consultancy'
            description="Technology is essential to run a competitive business. With the ever-changing environment keeping up can be challenging. Let our consultants help you get the most out of today's technology." 
            link="/consultancy"
            RightclassName={homestyle.servicesItemLeft}
            LeftclassName={homestyle.servicesItemRight}
            imgsrc={consultancy}
            imgalt='Business meeting'
            imgcredit="Image credits: Mikael Blomkvist Source:https://www.pexels.com/@mikael-blomkvist/"
          />
          <CardServices
            title='Software Services'
            description="Improve your business efficiency, make sure you have the right software for your needs."
            link="/software"
            RightclassName={homestyle.servicesItemRight}
            LeftclassName={homestyle.servicesItemLeft}
            imgsrc={software}
            imgalt='Business meeting'
            imgcredit="Image credits: Luis Gomes Source:https://www.pexels.com/photo/close-up-photo-of-programming-of-codes-546819/"
          />
          <CardServices
            title='IT Support'
            description="Struggling to resolve an issue, our experts can provide you with IT support 24/7 365 days a year."
            link="/support"
            RightclassName={homestyle.servicesItemLeft}
            LeftclassName={homestyle.servicesItemRight}
            imgsrc={support}
            imgalt='Woman starting at Laptop'
            imgcredit="Image credits:Andrea Piacquadio - Source: https://www.pexels.com/photo/young-troubled-woman-using-laptop-at-home-3755755/ "
          />
          <CardServices
            title='Hardware Services'
            description="The backbone of any modern business. We can install, upgrade or maintain your IT infrastructure"
            link="/hardware"
            RightclassName={homestyle.servicesItemRight}
            LeftclassName={homestyle.servicesItemLeft}
            imgsrc={hardware}
            imgalt='Server room'
            imgcredit="Image credits:Brett Sayles - Source: https://www.pexels.com/photo/server-racks-on-data-center-4508751/"
          />
        </section>
      </section>
    </section>
  );
};
export default OurServices;