import './components.css'

function TableItem (props) {

  return ( 
    <>
      <h2 className="card-item-title">{props.title}</h2>
      <p className="card-item-description">{props.description}</p>
        <table>
          <tr>
            <td>Price per User</td>
            <td>{props.price}</td>
          </tr>
          <tr>
            <td>Support Hours</td>
            <td>{props.hours}<br></br>{props.days}</td>
          </tr>
          </table>         
      </>

  )
}

export default TableItem