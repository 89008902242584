import './components.css'

function CardItem (props) {
  return ( 
      <div className="card-item-home">
        <p className="card-item-icon">{props.icon}</p>
        <h2 className="card-item-title">{props.title}</h2>
        <p className="card-item-description">{props.description}</p>
      </div>

  )
}

export default CardItem