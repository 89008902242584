import style from '../style/global.module.css'
import {Link} from 'react-router-dom'

const HomePage = () => {
    const textblock = {
      // marginLeft: "50px",
      // marginRight: "50px",
      textAlign: "left",
    };
  return (
    <section className={style.sectionA}>
      <section className={style.sectionB} id='AboutSection'>
      <h1><span className={style.highlight}>About </span> Us</h1>
        <p style={textblock}>Technology is constantly changing and keeping up to date with the latest standards whilst running your business is time consuming. Instead, use a trusted third-party provider like MMCY Business Solutions to take care of your business's IT needs. </p> 
        <p style={textblock}>MMCY Business Solutions was established in 2018 following years of experience in delivering complex IT projects in a diverse range of industries. We specialise in providing complete IT solutions to SME businesses.</p>
        <p style={textblock}>Based in central London, UK we support clients throughout the UK either remotely or on-site.</p>
        <p style={textblock}>Our staff have a range of skills from experienced project managers to software developers which allows us to provide a complete technology service.</p>
        <p style={textblock}>To see how you can run your business more efficient or  upgrade your current solutions, get in touch for a free no obligation assessment.</p>
        <div className={style.actions}>
              <Link to='/request'><button>Enquire now</button> </Link>
              </div>
      </section>
    </section>
  );
};

export default HomePage;