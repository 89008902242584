// import style from '../style/global.module.css';
import CardItem from '../components/CardItem';
import style from '../../style/global.module.css'
import { MdWifi, MdOutlineVideocam,MdOutlineSpeaker,MdDesktopWindows,MdPrint,MdPhoneAndroid,MdPointOfSale,MdMemory  } from 'react-icons/md';
import hwlife from '../static/Hardwarelifecycle.png';

const Hardware = () => {
  const services = [
    { 
      icon: <MdWifi/>,
      title: 'Network Infrastructure',
      description: 'We  can install the backbone behind your business',
    },
    { 
      icon: <MdOutlineVideocam/>,
      title: 'CCTV',
      description: 'Protect and remotely monitor your assets.',
    },
    {
      icon: <MdOutlineSpeaker/>,
      title: 'Audio visual',
      description: 'Conference equipment, TV, Projectors, Sounds systems.',
    },
    { 
      icon: <MdDesktopWindows/>,
      title: 'PCs',
      description: 'Laptops, Desktops, Monitors and Accessories'
    },
    { 
      icon: <MdPhoneAndroid/>,
      title: 'Mobile Phones',
      description: ''
    },
    { 
      icon: <MdMemory/>,
      title: 'Servers',
      description: ''
    },
    { 
      icon: <MdPointOfSale/>,
      title: 'EPOS',
      description: ''
    },
    { 
      icon: <MdPrint/>,
      title: 'Printers',
      description: ''
    },
  ]
// Other products, scanners, routers, 
  return (
    <>
    <section className={style.ContainerGrey} >
    <section className={style.sectionA}>
      <section className={style.sectionB}>
           <h1>Hardware <span className={style.highlight}> Services </span> </h1>
        <p>Our servies cover a wide range of IT Infrastructure.</p>
        <div className={style.ContainerColumnsFour}>
          {services.map((e) => 
            <CardItem 
            icon={e.icon} 
              title={e.title} 
              description={e.description}>
            </CardItem>
          )}
        </div>
        </section>
        </section>
        </section>
   
   
   
        <section className={style.sectionA}  style={{ marginTop: '0px' }}>
      <section className={style.sectionB}>
      <h1> <span className={style.highlight}>Hardware  </span> Lifecycle</h1>
          <p>It is vital that any business proactively maintains their IT infrastructure to prevent downtime and lost business.  Whether you're looking to upgrade or maintain your existing IT assets, we can support you throughout the hardware lifecycle.</p>
          <img src={hwlife} alt="Hardware Lifecycle" className={style.lifecyclImage} />
   
   
      </section>
    </section>
    </>
  );
};

export default Hardware;