import { useState, useEffect } from 'react';
import Slider from "react-slick";
import "./carousel.css";

import style from '../../style/global.module.css';
import homestyle from './homepage.module.css';
import AR from '../static/clientlogos/AR.png';
import GB from '../static/clientlogos/GB.png';
import HBL from '../static/clientlogos/HBL.png';
import HHC from '../static/clientlogos/HHC.png';
import Invinci from '../static/clientlogos/IA.png';
import LCSC from '../static/clientlogos/LCSC.png';
import LH from '../static/clientlogos/LH.png';
import LQID from '../static/clientlogos/LQID.png';
import PP from '../static/clientlogos/PP.png';


const ChangePageNamePageContent = () => {
  
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )
  
  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);
  
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: (!matches ? 2 : 4 ),
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    // pauseOnHover: true,
};



  return (
    <section className={homestyle.ContainerWhite} >
      <section className={style.sectionA} style={{ marginTop: '0px' , paddingBottom: '0px'}} id="clients">
        <section className={style.sectionB}>
          
          <h1>Our <span className={style.highlight}>Clients </span></h1>
          {/* {matches && (<h1>Big Screen</h1>)}
          {!matches && (<h3>Small Screen</h3>)} */}
          <p>We provide services to a diverse set of clients across a variety of industries. We have experience ranging from designing core banking system architecture for Fintechs to migrating SMEs to the cloud.  </p>
          <br></br>
          <Slider {...settings}>
          <img src={AR} alt="Arare Collection" className={homestyle.clientsLogo} />
          <img src={GB} alt="Gravity Bank Logo" className={homestyle.clientsLogo} />
          <img src={HBL} alt="HBL Bank Logo" className={homestyle.clientsLogo} />
          <img src={HHC} alt="Home Health Care Logo" className={homestyle.clientsLogo} />
          <img src={Invinci} alt="Invinci Logo" className={homestyle.clientsLogo} />
          <img src={LCSC} alt="London Corinthian Sailing Club Logo" className={homestyle.clientsLogo} />
          <img src={LH} alt="Linden House Logo" className={homestyle.clientsLogo} />
          <img src={LQID} alt="LQID Logo" className={homestyle.clientsLogo} />
          <img src={PP} alt="PP Logo" className={homestyle.clientsLogo} />
          </Slider>
        </section>
      </section>
    </section>
  );
};
export default ChangePageNamePageContent;
