import style from '../../style/global.module.css';

const Location = () => {
  return (
    <section className={style.sectionA}>
        <section className={style.sectionB} id='ContactUsSection'>
        <h1>Where to  <span className={style.highlight}> Test </span> </h1>
        <p> We are based in central London and provided our services to clients throughout the UK. We have fully embraced virtual working so we no longer have an office for customers to visit us. We do still like to meet our customers face to face so if required we can come visit your home or office.</p>
        </section>
    </section>
  );
};
export default Location;