import { Fragment } from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import { Outlet } from "react-router-dom";

const Layout = (props) => {
  return (
    <Fragment>
      <Navigation />
      <Outlet />
      <Footer />
    </Fragment>
  );
};

export default Layout;
