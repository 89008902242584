import CardItem from '../components/CardItem';
// import Testimonialsitem from '../components/Testimonials';
import style from '../../style/global.module.css'
import { MdOutlineAssignment, MdOutlineFlare, MdOutlineAddchart } from 'react-icons/md';
import { Link } from 'react-router-dom'

const Consulting = () => {
  const services = [
    {
      icon: <MdOutlineFlare />,
      title: 'Business Process Improvements',
      description: '',
    },
    {
      icon: <MdOutlineAddchart />,
      title: 'Systems Architecture Design',
      description: '',
    },
    {
      icon: <MdOutlineAssignment />,
      title: 'Change and Project Management',
      description: '',
    },
  ]

  // const testimonials = [
  //   { 
  //     // icon: <MdOutlineFlare/>,
  //     name: 'Berenice Hicks',
  //     organisation: 'London Corithinan Sailing Club',
  //     testimonial: 'tbc',
  //   },
  //   { 
  //     // icon: <MdOutlineFlare/>,
  //     name: 'Kea Kouvatsis',
  //     organisation: 'Linden House - Riverside',
  //     testimonial: 'tbc',
  //   },
  // ]

  return (
    <>      <section className={style.ContainerGrey} >
      <section className={style.sectionA} id="OurProcess">
        <section className={style.sectionB}>
          <h1><span className={style.highlight}>Consultancy </span> Services</h1>
          <p>Our consultants serve as your trusted advisors. As such, they offer comprehensive, multi-level assistance and aim to help you make the most strategically beneficial and well-informed decisions.</p>
          <p>In-line with our IT consultancy philosophy, our services will have a real, measurable impact on your business.</p>
          <div className={style.ContainerColumnsThree}>
            {services.map((e) =>
              <CardItem
                icon={e.icon}
                title={e.title}
                description={e.description}>
              </CardItem>
            )}
          </div>
        </section>
      </section>
    </section>
      <section className={style.sectionA} style={{ marginTop: '0px' }} id="OurProcess">
        <section className={style.sectionB}>
          <h1>Our <span className={style.highlight}> Process </span> </h1>
          <p>If you are interested in understanding how we can help you leverage the latest technology, then use our free initial assessment service that includes a report on what we can do for your business. It's free as we don't believe in charging you for what you already know and we value long term relationships with our clients.</p>
          <ol>
            <li>You book an initial assesmment.</li>
            <li>We will visit your office/site to understand your current setup and find out what you are looking to improve.</li>
            <li>We will produce a no obligation report about what we believe we can do for you and your business. </li>
            <li>Only if you are interested in what we have to offer, then you can choose to commission our services.</li>
          </ol>
          <div className={style.actions}>
            <Link to='/request'><button>Enquire now</button> </Link>
          </div>
        </section>
      </section>




      {/* <section className={style.sectionB}>
        <h1>Client Testimonials</h1>
        <p> </p>
        <div className={style.ContainerColumnsTwo}>
        {testimonials.map((e) => 
           <Testimonialsitem 
            icon={e.icon} 
            name={e.name} 
            organisation={e.organisation}
            testimonial={e.testimonial}>
           </Testimonialsitem>
        )}
        </div>
      </section> */}
    </>
  );
};

export default Consulting;