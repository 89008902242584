import React from 'react';
import {Link} from 'react-router-dom'
import PackageItem from '../components/PackageItem';
import style from '../../style/global.module.css'
import Accordion from '../../utilities/Accordion';
import { MdOutlineSupportAgent,MdAlarm,MdOutlinePersonAdd,MdLocationOn,MdAddTask,MdOutlineTaskAlt,MdOutlineCheck,MdOutlineMedicalServices } from 'react-icons/md';

const Support = () => {
  return (
    <section className={style.ContainerGrey} >
    <section className={style.sectionA}>
      <section className={style.sectionB} id="top">
        <h1>IT <span className={style.highlight}> Support</span></h1>
        <p>We provide a range of support to both indviudals and businesses.</p>
        <p>We believe in proactive support, to prevent problems, rather than reactive support to solve existing problems. We therefore highly recommend one of our support packages so that we will actively monitor your IT assets to minimise any down time.</p>
        </section>
        <section className={style.sectionB} id="ondemand">
          <br></br>
          <h1><span className={style.highlight}>On-Demand </span> Support</h1>
          <p>We believe in transparent pricing so you don't get any surprises.</p>
          <div className={style.ContainerColumnsTwo}>
          <div className="card-item">
            <p className="card-item-icon"><MdOutlineSupportAgent/></p>
            <h2 className="card-item-title">Remote Support</h2>
            <div className="card-item-description2" > 
              <p>Only £20 per 15 minutes.</p>
              <p> Minimum charge of 15 minutes.</p>
              <div className={style.actions}>
              <Link to='/request'><button>Enquire now</button> </Link>
              </div>
              <br></br> 
              <h3>Standard Support Hours</h3> 
              <p>Mon-Fri: 09:00- 17:00</p>
              <h3>Out of hours Support</h3>
              <p>Weekdays - £30 per 15 minutes</p>
              <p>Weekends and Bank Holidays- £40 per 15 minutes</p>
              <br></br>
              <p>No fix no fee guarantee</p>
              <p>Terms and Conditions Apply</p>
            </div>
          </div>
          <div className="card-item">
            <p className="card-item-icon"><MdLocationOn/></p>
            <h2 className="card-item-title">On-site Support</h2>
            <div className="card-item-description2" > 
              <p>£50 per call out plus</p>
              <p>£20 per 15 minutes whilst on-site</p>
              <div className={style.actions}>
              <Link to='/request'><button>Enquire now</button> </Link>
              </div>
              <br></br> 
              <h3>Areas covered</h3>
              <p>We are based in central London. For on-site support outside of TfL travel zone 3 we add a small travel charge.</p>
              <table className="card-item-table">
                <tr>
                  <th>Zone</th>
                  <th>Travel charge</th>
                </tr>
                <tr>
                  <td className="center">1-3</td>
                  <td className="center">None</td>
                </tr>
                <tr>
                  <td className="center">4</td>
                  <td className="center">£10</td>
                </tr>
                <tr>
                  <td className="center">5</td>
                  <td className="center">£20</td>
                </tr>
                <tr>
                  <td className="center">6</td>
                  <td className="center">£30</td>
                </tr>
                <tr>
                  <td>Outside Zone 6</td>
                  <td>Please contact us</td>
                </tr>
              </table> 
              <br></br>
              <p>Terms and Conditions Apply</p>
            </div>
          </div>
        </div>
        </section>
        <section className={style.sectionB} id="standardpackages">
        <br></br>
        <h1><span className={style.highlight}>Standard </span> Support Packages</h1>
          <p>Straight forward packages that let you focus on your business and not on IT.</p>
          <div className={style.ContainerColumnsThree}>

            <div className="card-item">
              <p className="card-item-icon2"><MdOutlineCheck/></p>
              <PackageItem
                title='Essentials'
                description="Takes care of the essentials required to run a business."
                price='£40'
                hours='09:00-17:00'
                days='5 days a week'
                >
              </PackageItem>
              <div className={style.actions}>
              <Link to='/request'><button>Enquire now</button> </Link>
              </div>
              <div className="accordion">
                <div className="accordion-item">
                <Accordion title=""
                  content={
                    <ul>
                      <li>Proactive IT asset management</li>
                      <h4>Software</h4>
                      <li>Domain management </li>
                      <li>Website hosting</li>
                      <li>Cloud productivity suites (Google Workspace or Microsoft 365)</li>
                      <li>Cloud productivity suites (Google Workspace or Microsoft 365)</li>
                      <h4>Hardware</h4>
                      <li>Laptop / desktop</li>
                    </ul>
                  }
                />
                </div>
              </div>
            </div>

            <div className="card-item">
              <p className="card-item-icon2"><MdOutlineTaskAlt/></p>
              <PackageItem
                title='Premium'
                description="Covers most of the day-to-day services and hardware."
                price='£60'
                hours='24 hours'
                days='5 days a week'
                >
              </PackageItem>
              <div className={style.actions}>
              <Link to='/request'><button>Enquire now</button> </Link>
              </div>
              <div className="accordion">
                <div className="accordion-item">
                <Accordion title=""
                  content={
                    <ul>
                      <li>Proactive IT asset management</li>
                      <h4>Software</h4>
                      <li>Domain management </li>
                      <li>Website hosting</li>
                      <li>Cloud productivity suites (Google Workspace or Microsoft 365)</li>
                      <li>Social media</li>
                      <li>Accounting systems</li>
                      <li>CRM systems</li>
                      <h4>Hardware</h4>
                      <li>Laptop / desktop</li>
                      <li>Mobile devices</li> 
                      <li>Printers</li> 
                    </ul>
                  }
                />
                </div>
              </div>
            </div>

            <div className="card-item">
              <p className="card-item-icon2"><MdAddTask/></p>
              <PackageItem
                title='Fully Managed'
                description="The complete package, covers your whole IT real estate."
                price='£125'
                hours='24 hours'
                days='7 days a week'
                >
              </PackageItem>
              <div className={style.actions}>
              <Link to='/request'><button>Enquire now</button> </Link>
              </div>
              <div className="accordion">
                <div className="accordion-item">
                <Accordion title=""
                  content={
                    <ul>
                      <li>Proactive IT asset management</li>
                      <h4>Software</h4>
                      <li>Domain management </li>
                      <li>Website hosting</li>
                      <li>Cloud productivity suites (Google Workspace or Microsoft 365)</li>
                      <li>Social media</li>
                      <li>Accounting systems</li>
                      <li>CRM systems</li>
                      <h4>Hardware</h4>
                      <li>Laptop / desktop</li>
                      <li>Mobile devices</li> 
                      <li>Printers</li> 
                      <li>Servers</li>
                      <li>CCTV</li>
                      <li>Audio Visual Equipment</li>
                      <li>EPOS & MPOS</li>
                    </ul>
                  }
                />
                </div>
              </div>
            </div>
      
        </div>
        <ul>
            <li>Minimum 12 month contract</li>
            <li>All prices are Exclusive of VAT</li>
            <li>Billed monthly in advanced by direct debit </li>
            <li>Support packages do not include licence costs for the underlying service or admin accounts that are required</li>
            <li>On-Demand Support prices apply for out of hours support</li>
            <li>Please refer to our SLAs for response times and target resolution times</li>
            <li>Minimum charges apply for business users </li>
            <li>Terms and Conditions apply </li>
          </ul>
        </section>
        <section className={style.sectionB} id="custompackages">
        <br></br>
        <h1><span className={style.highlight}>Custom </span> Support</h1>
          <p>Every business is unique so we offer custom support packages to be tailored to your business.</p>
          <p>Its easy as 1,2,3 to pick what you need to build your own package</p>
          <div className={style.actions}>
              <Link to='/request'><button>Enquire now</button> </Link>
              </div>
          <div className={style.ContainerColumnsCustomerSupport}>
            <div className="card-item">
              <p className="card-item-icon"><MdOutlinePersonAdd/></p>
              <h2 className="card-item-title">1. Users</h2>
              <div className="card-item-description2" > 
               <p>Select the number of users that require support.</p>
               <br></br>
               {/* <table> */}
                {/* <tr> */}
                  {/* <th>No. of users</th> */}
                  {/* <th>Base Price per user</th> */}
                {/* </tr> */}
                {/* <tr> */}
                  {/* <td>1</td> */}
                  {/* <td>£30</td> */}
                {/* </tr> */}
                {/* <tr> */}
                  {/* <td>2-4</td> */}
                  {/* <td>£25</td> */}
                {/* </tr> */}
                {/* <tr> */}
                  {/* <td>5-9</td> */}
                  {/* <td>£20</td> */}
                {/* </tr> */}
                {/* <tr> */}
                  {/* <td>10+</td> */}
                  {/* <td>£10</td> */}
                {/* </tr> */}
               {/* </table> */}
              </div>
            </div>
            <div className="card-item">
              <p className="card-item-icon"><MdOutlineMedicalServices/></p>
              <h2 className="card-item-title">2. Services</h2>
              <div className="card-item-description2" >
                <p>Add the services that they require.</p>
                <h4>Software</h4>
                <table>
                   <tr>
                    <td>Cloud productivity suites (Google Workspace or Microsoft 365)</td>
                    {/* <td>Included</td> */}
                  </tr>
                  <tr>
                    <td>Domain management</td>
                    {/* <td>£5 a month per domain</td> */}
                  </tr>
                  <tr>
                    <td>Website hosting support</td>
                    {/* <td>£5 a month per domain</td> */}
                  </tr>
                  <tr>
                    <td>Social media</td>
                    {/* <td>£5 a month per account per platform</td> */}
                  </tr>
                  <tr>
                    <td>Accounting systems</td>
                    {/* <td>£5 a month per user</td> */}
                  </tr>
                  <tr>
                    <td>CRM systems</td>
                    {/* <td>£5 a month per user</td> */}
                  </tr>
                </table>
                    <h4>Hardware</h4>
                  <table>
                  <tr>
                    <td>Laptop / desktops</td>
                    {/* <td>Enquire now</td> */}
                  </tr>
                  <tr>
                    <td>Mobile devices</td>
                    {/* <td>Enquire now</td> */}
                  </tr>
                  <tr>
                    <td>Printers</td>
                    {/* <td>Enquire now</td> */}
                  </tr>
                  <tr>
                    <td>Landline Phones</td>
                    {/* <td>Enquire now</td> */}
                  </tr>
                  <tr>
                    <td>Servers</td>
                    {/* <td>£100 a month per device</td> */}
                  </tr>
                  <tr>
                    <td>CCTV</td>
                    {/* <td>Enquire now</td> */}
                  </tr>
                  <tr>
                    <td>Audio Visual Equipment</td>
                    {/* <td>Enquire now</td> */}
                  </tr>
                  <tr>
                    <td>EPOS & MPOS</td>
                    {/* <td>Enquire now</td> */}
                  </tr>
                  </table>
              </div>
            </div>
            <div className="card-item">
              <p className="card-item-icon"><MdAlarm/></p>
              <h2 className="card-item-title">3. Support hours</h2>
              <div className="card-item-description2" > 
              <table>
                <tr>
                  <th>Package</th>
                  <th>Days</th>
                  <th>Hours</th>
                  {/* <th>Per User</th> */}
                </tr>
                <tr>
                  <td>9-5/5</td>
                  <td>Mon-Fri<sup>1</sup></td>
                  <td>09:00-17:00</td>
                  {/* <td>Included</td> */}
                </tr>
                <tr>
                  <td>24/5</td>
                  <td>Mon-Fri<sup>1</sup></td>
                  <td>24 hours</td>
                  {/* <td>£10</td> */}
                </tr>
                <tr>
                  <td>9-5/7</td>
                  <td>Mon-Sun<sup>2</sup></td>
                  <td>09:00-17:00</td>
                  {/* <td>£15</td> */}
                </tr>
                <tr>
                <td>24/7</td>
                  <td>Mon-Sun<sup>2</sup></td>
                  <td>24 hours</td>
                  {/* <td>£20</td> */}
                </tr>
              </table> 
<p><sup>1</sup> Excludes Bank Holidays</p>
<p><sup>2</sup> Includes Bank Holidays</p>
<li>On-demand pricing applies for out of hours support</li>
<li>Please refer to SLAs for response times and target resolution times</li>
              </div>
            </div>
        </div>
        </section>              
    </section>
    </section>
  );
};

export default Support