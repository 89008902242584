
const footer = () => {
  const d = new Date();

  return (
    <footer>
      <p>© 2018-{d.getFullYear()} MMCY Business Solutions Ltd </p>
      <p>This website is designed, built and hosted by MMCY Business Solutions Ltd</p>
      <p>MMCY Business Solutions Ltd is a limited company registered in England and Wales. Registered number 11236742</p>
    </footer>
  );
};

export default footer;