import homestyle from './homepage.module.css';
import {Link} from 'react-router-dom';

const CardServicesLeft = (props) => {
  return (
    <Link  to={props.link} style={{color:"black"}}>
      <div className={homestyle.servicesContainer}>
        <div className={homestyle.servicesCard}>
          <div className={props.LeftclassName}>
            <h2>{props.title}</h2>
            <p>{props.description}</p>
            <br></br>
            <p className={homestyle.servicesCardLink}>Find out more</p>
          </div>
          <div className={props.RightclassName}>
            <img src={props.imgsrc} className={homestyle.servicesImage} alt={props.imgalt} />
            <p hidden>{props.imgcredit}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default CardServicesLeft;