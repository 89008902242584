import style from '../../style/global.module.css'
import HeroImage from './HeroImage';
import OurServices from './OurServices';
import OurApproach from './OurApproach';
import OurClients from './OurClients';
import Testimonies from './Testimonies';
import ContactUs from '../forms/ContactUs';


const HomePage = () => {

  return (
    <>
      <section className={style.sectionA} style={{ paddingBottom: '0px'}} id='home'>
        <HeroImage />
        <OurServices />
        <OurApproach />
        <OurClients />
        <Testimonies />
      </section>
      <ContactUs />
    </>
  );
};

export default HomePage;

