import cityoflondonatnight from '../static/homepageimages/CityoflondonDark2.jpg';
import mainimagestyle from './homepage.module.css';
import mmcylogowhite from '../static/mmcylogos/SecondaryTransparent-Opt.svg';

function MainImage() {

  return (
    <section>
      <div className={mainimagestyle.mainImageContainer}>
        <img src={cityoflondonatnight} className={mainimagestyle.mainImage}
          alt="City of London at Night" />
        <div className={mainimagestyle.content}>
          <img src={mmcylogowhite} className={mainimagestyle.mmcylogo}
            alt="MMCY Business Solutions Logo" />
          <p><b>Powering the technology behind your business</b></p>
        </div>
      </div>
    </section>
  );
}


export default MainImage