import style from '../../style/global.module.css';
import thankyou from '../static/Thankyou.png';


const ThankYou = () => {
  return (
    <section className={style.sectionA}>
      <section className={style.sectionB}>
        <img src={thankyou} alt="Thank you" className={style.thankyouImage} />
        <br></br>
        <br></br>
        <p>Your request has been received by MMCY Business Solutions. We will get in touch with you shortly.</p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </section>
    </section>
  );
};

export default ThankYou;

